import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Modal from '../components/Modal'
// import SampleForm from '../components/Form'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import logoIcon from '../img/logo-icon.svg'

// Images
import logoIconWhite from '../img/logo-icon-white.svg'
import purity from '../img/physical-purity.png'
import shape from '../img/shape-distribution.png'
import mechanical from '../img/mechanical-damage.png'
import pathogen from '../img/pathogen.png'
import foreign from '../img/foreign.png'
import customapplication from '../img/custom-applications.png'
import surface from '../img/surface-chemistry.png'
import video1 from '../../static/video/skyway-feed.mp4'
import ReactPlayer from 'react-player/youtube'

class FeedAnalytics extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Feed Analytics" description={siteDescription} />

        <div className="hero text-background-banner">
          <div>
            <div className="row">
              <div className="col-md-8 offset-md-2 text-center">
                <h1 className="text-white">
                  Take control of your feed quality
                  <br />
                </h1>
                <h5 className="mt-0 text-white">
                  Fast, repeatable and traceable results in seconds
                </h5>
                <Modal bsPrefix="btn-global" html="Test My Seed Samples">
                  <iframe
                    className="-0"
                    title="calendaly"
                    src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                    height="700px"
                    width="100%"
                    frame="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </Modal>
              </div>
            </div>
          </div>
          <div className="hero-slider-bg">
            <ReactPlayer
              className="hero-video"
              url="https://www.youtube.com/watch?v=aJ4pROlu8f0"
              muted={true}
              playing={true}
              loop={true}
              width="202.78vh"
              height="114vh"
              style={{
                position: 'absolute',
                transform: 'transform: translate(-50%,-50%) scale(1.15);',
              }}
            />
          </div>
        </div>

        <div className="container">
          <div className="row px-4 justify-content-center pt-5">
            <h2 className="pb-0 text-center mt-5">
              <img src={logoIcon} className="logo-icon" alt="Logo" />
              Join the Movement
            </h2>
            <p>
              Traditional methods of manual feed quality assessment are
              effective – but often labor intensive, subjective or slow.
            </p>
            <p>
              Harness the power of automated imaging with a fast and flexible
              feed quality system.
            </p>
            <p>
              Reduce fines, disputes and over-paying for ingredients with
              high-quality, traceable quality data.
            </p>
            <p>
              The following tests can all be simultaneously completed in
              seconds:
            </p>
          </div>
          <div className="row px-4 justify-content-center pt-5">
            <div className="col-md-10 mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={purity}
                      alt="Physical Purity"
                    />
                    <h5 className="m-0">Ingredient quality</h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={shape}
                      alt="shape distribution"
                    />
                    <h5 className="m-0">Size and shape distribution</h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={mechanical}
                      alt="Mechanical damage"
                    />
                    <h5 className="m-0">
                      Mechanical damage (broken, skinned and cracked kernels)
                    </h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={surface}
                      alt="surface chemistry"
                    />
                    <h5 className="m-0">Color and surface chemistry</h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={pathogen}
                      alt="Pathogen"
                    />
                    <h5 className="m-0">
                      Pathogen detection (fusarium, aflatoxin and other molds)
                    </h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={foreign}
                      alt="Foreign"
                    />
                    <h5 className="m-0">Foreign material detection</h5>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-4">
                  <div className="text-center  h-100 px-3 py-4">
                    <img
                      className="img-fluid mb-3"
                      width={80}
                      src={customapplication}
                      alt="custom applications"
                    />
                    <h5 className="m-0">Other custom applications</h5>
                  </div>
                </div>
                <iframe
                  width="100%"
                  height="515"
                  src="https://www.youtube.com/embed/PM6sv1EhKg8"
                  title="YouTube video player"
                  frame="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          <div className="row justify-content-center py-5">
            <Modal bsPrefix="btn-global mx-2 my-2" html="Get a Quote">
              <iframe
                className="-0"
                title="calendaly"
                src="https://calendly.com/skywayanalytics/skyway-analytics-learn-more-website-faq-clone?month=2019-11"
                height="700px"
                width="100%"
                frame="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Modal>
            <Modal bsPrefix="btn-global mx-2 my-2" html="Test My Feed Samples">
              <iframe
                className="-0"
                title="calendaly"
                src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                height="700px"
                width="100%"
                frame="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Modal>
          </div>
        </div>
      </Layout>
    )
  }
}

export default FeedAnalytics

export const FeedAnalyticsQuery = graphql`
  query FeedAnalyticsQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
